import React from 'react';

import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';
import hearts from '../assets/images/RamadanHearts.jpg';

const Current = () => (
  <Layout>
    <Sidebar showTabs="false" />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="hearts"
      >
        <div className="w-100">
          <h2 className="mb-5">Purifying our Hearts اصلاح القلوب </h2>
          <a
            href="https://www.youtube.com/playlist?list=PLNT8BTFvQYHKJMa-hKZvNDe0xgknoHwdY"
            target="blank"
          >
            Purifying our Hearts (YouTube Videos PlayList)
          </a>
          <p className="lead mb-5">
            Allah said in the Quran: "(On the Day in which no wealth or children
            will benefit a person except for the one who comes with a pure
            heart)" "يوم لا ينفع مال ولا بنون الا من اتى الله بقلب سليم" سورة
            الشعراء 88-89 ألا وَإنَّ في الجَسَدِ مُضْغَةً إذَا صَلَحَتْ صَلَحَ
            الجَسَدُ كُلُّهُ، وَإِذَا فَسَدَتْ فَسَدَ الجَسَدُ كُلُّهُ ألاَ
            وَهِيَ القَلْبُ Beware, in the body there is a piece of flesh; if it
            is healthy, the whole body is healthy and if it is corrupted the
            whole body is corrupted, and that it is the heart.‏ Bukhari and
            Muslim الراوي: النعمان بن بشير المحدث: مسلم - المصدر: صحيح مسلم -
            الصفحة أو الرقم: 1599 المحدث: البخاري - المصدر: صحيح البخاري -
            الصفحة أو الرقم: 52 خلاصة حكم المحدث: صحيح وعن أبي هريرة قال قال
            رسول الله صلى الله عليه وسلم إنَّ اللَّهَ لا ينظرُ إلى أجسادِكُم ،
            ولا إلى صورِكُم ، ولَكِن ينظرُ إلى قلوبِكُم وأشارَ بأصابعِهِ إلى
            صدرِهِ رواه مسلم " Verily Allah does not look to your bodies nor to
            your faces but He looks to your hearts," and he pointed towards the
            heart with his fingers.‏ Muslim الراوي: أبو هريرة المحدث: مسلم -
            المصدر: صحيح مسلم - الصفحة أو الرقم: 2564 خلاصة حكم المحدث: صحيح He
            peace be upon him also said in another sound Hadith: (التقوى هاهنا،
            التقوى هاهنا، التقوى هاهنا. وأشار إلى صدره ثلاث مرات) (Piety/God
            consciousness is here, Piety/God consciousness is here, Piety/God
            consciousness is here. And he pointed to his chest three times)
            Al-Qntara, () is a small Bridge, between Paradise and Jahanm. This
            will be the last stage, before entering Paradise, when people will
            pay their debt to others. as Narrated by Muslim and Bukhari
          </p>

          <ol>
            <li>
              <a href="https://youtu.be/uanY_tvGg58" target="blank">
                Purifying our Hearts - Introduction (YouTube Video)
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/playlist?list=PLNT8BTFvQYHLmHSeXABFEYS-hZ9IwGdWI"
                target="blank"
              >
                Purifying our Hearts Best Medication Ramdan (YouTube Video):
              </a>
            </li>
            <ul className={Classes.FocusLinked}>
              <li>
                <a
                  href="https://iloveallah.net/static/RamadanAndOurHearts.pdf"
                  target="blank"
                >
                  Written pdf to download
                </a>
              </li>
            </ul>
            <li>
              <a href="https://youtu.be/rgUxIZCvdX4" target="blank">
                Purifying our Hearts First Medication (YouTube Video):
              </a>
            </li>
            <li>
              <a href="https://youtu.be/5yKvZ_WkKoM" target="blank">
                Let's Prepare for Ramadan by Purifying our Hearts-Introduction
                (YouTube Video):
              </a>
            </li>
            <li>
              <a href="https://youtu.be/UIguNrGpzpg" target="blank">
                Purifying our Hearts Second Medication (YouTube Video):
              </a>
            </li>
            <li>
              <a href="https://youtu.be/EWAr1-F4BzQ" target="blank">
                Purifying our Hearts First Sickness LowerTheGaze (YouTube
                Video):
              </a>
            </li>
          </ol>

          <h4>References:</h4>
          <ul>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_intro.mp3"
                target="blank"
              >
                [Arabic Only - MP3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Introduction (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_ekhlas.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Ikhlas (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_tawbah.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Repent (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_tawakol.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Rely on Allah (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_taqua.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Taqua (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_infaq.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - charity (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_jannah.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Paradise (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_mawt.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - death (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_islam.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Islam (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_reda.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Reda (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_yaqeen.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Yaqeen (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_3efah.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - 3efah (mp3) - Amr Khaled
              </a>
            </li>
            <li>
              <a
                href="https://iloveallah.net/static/hearts_amr_qiamah.mp3"
                target="blank"
              >
                [Arabic Only - mp3] اصلاح القلوب عمرو خالدPurification of the
                hearts - Qiamah (mp3) - Amr Khaled
              </a>
            </li>
          </ul>
          <img
            className="img-fluid img-profile mx-auto mb-2"
            src={hearts}
            alt="PSM"
          />
        </div>
      </section>
    </div>
  </Layout>
);

export default Current;
